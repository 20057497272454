import React from "react";
import { useLocation } from "react-router-dom";
import { message } from "antd";

import { IUser } from "../interfaces/user";
import { currencyCountryMap } from "../constants/currencyCountryMap";

import { emailInStringRegex, gstRegex, urlRegex } from "./regex";
import { isString } from "./type-util";

/**
 * Logs the user out by clearing local storage and setting the user to undefined.
 *
 * @param {function} setUser - A function that sets the user to undefined.
 *
 * @description
 * The `handleLogout` function is used to log the user out of the application. It performs the following actions:
 *
 * 1. Clears the local storage, removing any user-related data that might be stored.
 * 2. Sets the user to undefined, effectively logging the user out of the application.
 *
 * @example
 * // Example usage:
 * const handleLogout = (setUser) => {
 *   localStorage.clear(); // Clear local storage data
 *   setUser(undefined);   // Set the user to undefined
 * };
 */
export const handleLogout = (setUser: (user: IUser | undefined) => void) => {
  localStorage.clear();
  setUser(undefined);
};

/**
 * Parses and extracts query parameters from the current URL's search string using the URLSearchParams API.
 *
 * @returns {URLSearchParams} A URLSearchParams object representing the query parameters.
 *
 * @example
 * // Example usage:
 * const query = useQuery();
 *
 * // Assuming the current URL is "https://example.com?param1=value1&param2=value2"
 * const value1 = query.get("param1"); // Returns "value1"
 * const value2 = query.get("param2"); // Returns "value2"
 */

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Default Pagination Configuration for Tables.
 *
 * @description
 * The `defaultTablePagination` object defines the default pagination configuration for tables. It specifies the initial values for pagination settings commonly used in table components.
 *
 * - `current`: Initial page number, set to 1 by default.
 * - `pageSize`: Initial number of items displayed per page, set to 15 by default.
 * - `total`: Total number of items in the table (used for pagination calculations), set to 0 by default.
 *
 * @example
 * // Example usage:
 * const tablePagination = defaultTablePagination;
 *
 * // This sets up a table with default pagination configuration.
 */
export const defaultTablePagination = {
  current: 1,
  pageSize: 15,
  total: 0,
};

/**
 * Creates a debounced version of a function that delays its execution until after a specified time interval.
 *
 * @param {function} func - The function to be debounced.
 * @param {number} [delay=300] - The time interval (in milliseconds) to wait before executing the debounced function.
 *
 * @returns {function} A debounced version of the input function.
 *
 * @example
 * // Example usage:
 * const debouncedFunction = debounce((value) => {
 *   console.log(`Debounced: ${value}`);
 * }, 500);
 *
 * debouncedFunction("First Call"); // This call will be delayed by 500 milliseconds.
 * debouncedFunction("Second Call"); // Only the second call will execute after the delay.
 *
 * @param {function} func - The function to be debounced.
 * @param {number} [delay=300] - The time interval (in milliseconds) to wait before executing the debounced function.
 */
export const debounce = (func: (...args: any) => void, delay = 300) => {
  let timer: any;
  return (...filterArgs: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, filterArgs), delay);
  };
};

/**
 * Generates file name information from a given file URL or path.
 *
 * @param {string} fileUrl - The URL or path of the file for which you want to extract information.
 *
 * @returns {object} An object containing three properties:
 *   - `nameWithoutExt`: The file name without the file extension.
 *   - `extension`: The file extension, including the dot (e.g., ".pdf").
 *   - `fileName`: The complete file name, including the extension.
 *
 * @example
 * // Example usage:
 * const fileUrl = "https://example.com/files/document.pdf";
 * const { nameWithoutExt, extension, fileName } = generateFileName(fileUrl);
 *
 * // Resulting values:
 * // nameWithoutExt: "document"
 * // extension: ".pdf"
 * // fileName: "document.pdf"
 *
 * @param {string} fileUrl - The URL or path of the file.
 */
export const generateFileName = (fileUrl: string) => {
  const fileName = fileUrl?.substring(fileUrl?.lastIndexOf("/") + 1);
  const nameWithoutExt = fileName?.substring(0, fileName?.lastIndexOf("."));
  const extension = fileName?.substring(fileName?.lastIndexOf("."));
  return { nameWithoutExt, extension, fileName };
};

/**
 * Checks the validity of a Goods and Services Tax (GST) number.
 *
 * @param {string} value - The GST number to be validated.
 *    - The GST number should be a 15-character alphanumeric string in a specific format.
 *
 * @returns {boolean} - Returns true if the provided GST number is valid, false otherwise.
 *
 * @example
 * const validGSTNumber = "12ABCDE1234F1Z5";
 * const isGSTValid = isValidGSTNumber(validGSTNumber);
 * console.log(isGSTValid); // Output: true
 *
 * @example
 * const invalidGSTNumber = "INVALID123456";
 * const isGSTValid = isValidGSTNumber(invalidGSTNumber);
 * console.log(isGSTValid); // Output: false
 */
export const isValidGSTNumber = (value: string) => {
  return gstRegex.test(value);
};

/**
 * Checks if a given value is neither `undefined` nor `null`.
 *
 * @param {any} value - The value to check.
 * @returns {boolean} - Returns `true` if the value is neither `undefined` nor `null`, and `false` otherwise.
 *
 * @example
 * // returns true
 * valueExists("Hello, world!")
 *
 * @example
 * // returns false
 * valueExists(undefined)
 *
 * @example
 * // returns false
 * valueExists(null)
 */
export const valueExists = (value: any): boolean =>
  value !== undefined && value !== null && value !== "";

/**
 * Getting the error response message
 *
 * @param   {Object}    error      The error response.
 * @returns {String}
 */
export function getApiErrorMsg(error: any, message?: string) {
  if (isString(error?.response?.data?.message)) {
    return error.response.data.message;
  } else if (isString(error)) {
    return error;
  } else {
    return message ?? "Something went wrong. Please try again";
  }
}

/**
 * Getting the success response message
 *
 * @param   {Object}    response      The success response.
 * @returns {String}
 */
export function getApiResponseMsg(resp: any, message?: string) {
  if (isString(resp?.data?.message)) {
    return resp.data.message;
  } else if (isString(resp?.message)) {
    return resp.message;
  } else if (isString(resp)) {
    return resp;
  } else {
    return message ?? "Successfully Executed!";
  }
}

export function formatTillThreeDecimals(value: number, toFixed?: boolean) {
  const newVal = Math.round((value || 0) * 1000) / 1000;
  return toFixed ? parseFloat(newVal.toFixed(3)) : newVal;
}

export function getFormattedAmount(amount: number, currency?: string) {
  const code = currency ? currencyCountryMap[currency]?.symbol || currency : "";

  const formattedAmount = Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);

  return `${code} ${formattedAmount}`;
}

export function formatCurrencyIndian(value: number) {
  const suffixes = ["", "K", "L", "Cr"];

  let suffixIndex = 0;
  let compareAmount = 1000;
  while (value >= compareAmount && suffixIndex < suffixes.length - 1) {
    value /= compareAmount;
    suffixIndex++;
    compareAmount = 100;
  }

  // Format value with a comma and add the appropriate suffix
  const formattedValue = value.toLocaleString("en-IN", {
    maximumFractionDigits: 2,
  });

  return formattedValue + suffixes[suffixIndex];
}

export function downloadBlobTypeResponse(resp: any, fileName: string) {
  if (resp.status === 200) {
    const blob = new Blob([resp.data], { type: resp.data.type });
    const url = window.URL.createObjectURL(blob);
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.download = fileName;
    aTag.target = "_blank";
    document.body.appendChild(aTag);
    aTag.click();
    document.body.removeChild(aTag);

    getSuccessToast(
      `download-${fileName}-file`,
      `${fileName} file download successfully.`
    );
  } else {
    getErrorToast(
      `download-${fileName}-file`,
      `Unable to download ${fileName} file.`
    );
  }
}

export const getSuccessToast = (
  key: string,
  resp: any,
  defaultMsg?: string,
  duration?: number
) => {
  return message.success({
    content: getApiResponseMsg(resp, defaultMsg),
    key: key || "api-success",
    duration: duration || 3,
  });
};

export const getErrorToast = (
  key: string,
  err: any,
  defaultMsg?: string,
  duration?: number
) => {
  message.error({
    content: getApiErrorMsg(err, defaultMsg),
    key: key || "api-error",
    duration: duration || 3,
  });
};

export const replaceEmailInString = (str: string, email: string) => {
  return emailInStringRegex.test(str)
    ? str.replaceAll(emailInStringRegex, email)
    : str;
};

export const getShiprocketErrorText = (
  errorObj: any,
  contactEmail?: string,
  defaultMsg?: string
) => {
  const errorKeys = [
    "non_field_errors",
    "pickup_date_time",
    "no_of_packages",
    "approx_weight",
    "is_insured",
    "is_to_pay",
    "to_pay_amount",
    "source_warehouse_name",
    "source_address_line1",
    "source_address_line2",
    "source_pincode",
    "source_city",
    "source_state",
    "sender_contact_person_name",
    "sender_contact_person_email",
    "sender_contact_person_contact_no",
    "destination_warehouse_name",
    "destination_address_line1",
    "destination_address_line2",
    "destination_pincode",
    "destination_city",
    "destination_state",
    "recipient_contact_person_name",
    "recipient_contact_person_email",
    "recipient_contact_person_contact_no",
    "client_id",
    "packaging_unit_details",
    "recipient_GST",
    "supporting_docs",
    "is_cod",
    "cod_amount",
    "mode_name",
    "client_id",
    "order_id",
    "remarks",
    "recipient_GST",
    "to_pay_amount",
    "mode_id",
    "delivery_partner_id",
    "pickup_date_time",
    "eway_bill_no",
    "invoice_value",
    "invoice_number",
    "invoice_date",
    "supporting_docs",
    "no_transporter_id",
    "no_client_id",
  ];
  for (const key of errorKeys) {
    if (errorObj?.[key] && isString(errorObj[key]?.[0])) {
      return contactEmail
        ? replaceEmailInString(errorObj[key][0], contactEmail)
        : errorObj[key][0];
    }
  }

  return defaultMsg ?? "Something went wrong. Please try again";
};

export const getTransitTimeExtraText = (quotation: any) => {
  const { pickupTransitTime, deliveryTransitTime } = quotation || {};

  if (pickupTransitTime && deliveryTransitTime) {
    return `(inclusive of ${pickupTransitTime} days Pickup and ${deliveryTransitTime} days Delivery Time)`;
  }

  if (pickupTransitTime) {
    return `(inclusive of ${pickupTransitTime} days Pickup Time)`;
  }

  if (deliveryTransitTime) {
    return `(inclusive of ${deliveryTransitTime} days Delivery Time)`;
  }

  return "";
};

export const getChargesExtraText = (
  isUserIndian: boolean,
  bookingDetails: any
) => {
  if (isUserIndian) {
    if (
      (bookingDetails?.bookingType === "FCL" &&
        bookingDetails?.bookingPickUpPort?.country?.name !== "UNITED STATES" &&
        bookingDetails?.incoTerms === "CIF") ||
      (bookingDetails?.bookingType === "LCL" &&
        bookingDetails?.incoTerms === "CIF")
    ) {
      return "Destination charges not included";
    } else if (bookingDetails?.incoTerms === "FOB") {
      return "Origin charges not included";
    }
  } else if (bookingDetails?.incoTerms === "FOB") {
    return "Origin and destination charges not included";
  } else if (
    (bookingDetails?.bookingType === "FCL" &&
      bookingDetails?.bookingPickUpPort?.country?.name !== "UNITED STATES" &&
      bookingDetails?.incoTerms === "FCA") ||
    (bookingDetails?.bookingType === "LCL" &&
      bookingDetails?.incoTerms === "FCA")
  ) {
    return "Destination charges not included";
  } else if (bookingDetails?.incoTerms === "CIF") {
    return "Custom clearance charges not included";
  }
};

export const convertToMeter = (
  value?: number,
  unit?: "m" | "cm" | "ft" | "in"
) => {
  const targetUnit = "m";
  const conversionFactors = {
    m: 1,
    cm: 0.01, // 1 cm = 0.01 meters
    ft: 0.3048, // 1 foot = 0.3048 meters
    in: 0.0254, // 1 inch = 0.0254 meters
  };

  const sourceFactor = (unit && conversionFactors[unit]) || 1;
  const targetFactor = conversionFactors[targetUnit] || 1;

  return value ? value * (sourceFactor / targetFactor) : 0;
};

export const bookingTypeTagColor = (type: any) => {
  const map: any = {
    LCL: "geekblue",
    FCL: "pink",
  };
  return map?.[type] ?? "--";
};

export const routingTypeTagColor = (type: any) => {
  const map: any = {
    IMPORT: "orange",
    EXPORT: "cyan",
  };
  return map?.[type] ?? "--";
};

export const isValidURL = (url: string) => {
  return !!url?.match(urlRegex);
  // return urlRegex.test(url);
};

export const earliestDateLabel = (details: any) => {
  if (details?.pickUpType === "DOOR") {
    return "Pickup Date";
  }
  if (details?.bookingType === "FCL") {
    return "Earliest Container Pickup Date";
  }
  return "Earliest Carting Date";
};

export const validateForPositiveValue = (value: number, message?: string) => {
  if (valueExists(value) && value <= 0) {
    return Promise.reject(new Error(message ?? "Please enter a valid value"));
  }
  return Promise.resolve();
};

export const positiveValueValidator = (message?: string) => {
  return {
    validator: async (_: any, value: number) =>
      validateForPositiveValue(value, message),
  };
};
